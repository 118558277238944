import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import Button from 'common/components/Button';
import ParticlesComponent from '../../App/particles';
import imgBack1 from 'common/assets/image/landing/background-1.png';
import imgBack2 from 'common/assets/image/landing/background-2.png';
import imgBack3 from 'common/assets/image/landing/background-3.png';
import imgBack4 from 'common/assets/image/landing/background-4.png';
import imgBack5 from 'common/assets/image/landing/background-5.png';

import { BannerSectionContainer, BackgroundPreLoader } from './styles';

const BannerSection = () => {
  const backImgs = [imgBack1, imgBack2, imgBack3, imgBack4, imgBack5];
  const [backImgIndex, setBackImgIndex] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      setBackImgIndex((backImgIndex + 1) % 5);
    }, 5000);
  }, [backImgIndex]);

  const handlePassengerClick = () => {

  }

  const handleDriverClick = () => {
    navigate('/driver');
  }

  return (
    <BannerSectionContainer imgBack={backImgs[backImgIndex]}>
      <ParticlesComponent />
      <div className="banner--wrapper">
        <div className="banner--content-container">
          <p className="banner--sub-heading">READY WHEN YOU ARE</p>
          <h2 className="banner--heading">Wherever you’re headed, let’s get there together.</h2>
          <div className="banner--button-container">
            <Button className="banner--button-passenger" title="Sign up to ride" onClick={handlePassengerClick} />
            <Button className="banner--button-driver" title="Apply to drive" onClick={handleDriverClick} />
          </div>
        </div>
      </div>
      <BackgroundPreLoader img={imgBack1} />
      <BackgroundPreLoader img={imgBack2} />
      <BackgroundPreLoader img={imgBack3} />
      <BackgroundPreLoader img={imgBack4} />
      <BackgroundPreLoader img={imgBack5} />
    </BannerSectionContainer>
  );
}

export default BannerSection;