import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from 'common/components/UI/Container';
import Button from 'common/components/Button';
import useWindowSize from "utils/windowSize";
import Slider from "react-slick";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import FeaturesSliderWrapper from './featuresSlider.style';
import ItemArrow from 'assets/imgs/item-arrow.svg'

const FeaturesSlider = (props) => {
  const { data, posts, button, openLogin } = props;
  const { width } = useWindowSize();
  const slider1 = useRef();
  const slider2 = useRef();
  const [sliderIndex, setSliderIndex] = useState(0);

  const SampleNextArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <ChevronRightIcon
        className={className}
        style={{
          ...style,
          display: 'block',
          right: 10,
          zIndex: 1,
          color: 'black',
          opacity: 0.7,
          width: 40,
          height: 40,
          background: 'white',
          borderRadius: '50%',
          boxShadow: '0px 3px 6px 1px rgb(160, 161, 188, 0.35)',
          bottom: width > 992 ? 0 : -10,
          top: 'unset',
          padding: 5,
        }}
        onClick={onClick}
      />
    )
  }

  const SamplePrevArrow = (props) => {
    const { className, style, onClick } = props
    return (
      <div className="arrow-container">
        <ChevronLeftIcon
          className={className}
          style={{
            ...style,
            display: 'block',
            right: 70,
            left: width > 992 ? 'unset' : 10,
            zIndex: 1,
            color: 'black',
            opacity: 0.7,
            width: 40,
            height: 40,
            background: 'white',
            borderRadius: '50%',
            boxShadow: '0px 3px 6px 1px rgb(160, 161, 188, 0.35)',
            bottom: width > 992 ? 0 : -10,
            top: 'unset',
            padding: 5,
          }}
          onClick={onClick}
        />
      </div>
    )
  }

  const settings = {
    dots: width > 992 ? false : true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    adaptiveHeight: true,
    speed: 100,
    beforeChange: (current, next) =>
      setSliderIndex(next)
  };

  const mobileSettings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    className: "center",
    centerPadding: "0px",
    beforeChange: (current, next) =>
      setSliderIndex(next)
  };

  const handleClickTitle = (id) => {
    slider1.current.slickGoTo(id);
  }

  const handleLoginModal = () => {
    openLogin();
  };

  const getCurrentPost = (slug) => {
    return posts.filter(post => post.slug === slug)[0];
  }

  const isShowHeading = (items) => {
    let isShow = false;
    items.map(item => {
      const post = getCurrentPost(item.slug);
      if (post) {
        isShow = true;
      }
    })
    return isShow;
  }

  const renderItem = (item) => {
    const post = getCurrentPost(item.slug);
    if (post) {
      return (
        <div className="features-slider--hero-section" key={`slider-${item.index}`}>
          <div className="features-slider--container">
            {post && post.title && <h1 className="features-slider--entry-title">{post.title}</h1>}
            {post && post.content && <div className="features-slider--entry-content" dangerouslySetInnerHTML={{__html: post.content}} />}
            <Button
              className="btn--driver-signup"
              {...button}
              title="Start Free Trial" 
              onClick={handleLoginModal}
            />
          </div>
        </div>
      )
    }
    return null;
  }

  let selNavOffsetTop = 111;
  let selObj = null;
  if (
    typeof window === 'object' &&
    (selObj = document.getElementById(`features_slider_navigation_item_${sliderIndex}`))
  ) {
    selNavOffsetTop = selObj.offsetTop;
  }
  return (
    <FeaturesSliderWrapper>
      <h2 className="features-slider--title">Features</h2>
      <Container>
        <div className="features-slider--section--list">
          {data.map(element => (
            <div className="features-slider--section--list-group" key={`${element.heading}-${element.id}`}>
              {isShowHeading(element.items) && <p className="features-slider--section--list-heading">{element.heading}</p>}
              {element.items.map(item => {
                const post = getCurrentPost(item.slug);
                if (post) {
                  return (
                    <p id={`features_slider_navigation_item_${item.index}`} key={`title-${item.index}`} onClick={() => handleClickTitle(item.index)} className={`features-slider--section--list-title ${item.index === sliderIndex ? 'active' : ''}`}>{post.title}</p>
                  );
                }
                return null;
              })}
            </div>
          ))}
          <img src={ItemArrow} alt="item-arrow" style={{ top: selNavOffsetTop }} />
          <Slider asNavFor={slider1.current} ref={slider => (slider2.current = slider)} {...mobileSettings} className="features-slider--mobile-title-slider">
            {data.map(element => (
              element.items.map(item => {
                const post = getCurrentPost(item.slug);
                if (post) {
                  return (
                    <p key={`title-${item.index}`} className={`features-slider--mobile-slider-title ${item.index === sliderIndex ? 'active' : ''}`} onClick={() => handleClickTitle(item.index)}>{post.title}</p>
                  );
                }
                return null;
              })
            ))}
          </Slider>
        </div>
        <div className="features-slider--section--slider">
          <Slider asNavFor={slider2.current} ref={slider => (slider1.current = slider)} {...settings} className="features-slider--desktop-slider">
            {data.map(element => (
              element.items.map(item => (
                renderItem(item)
              ))
            ))}
          </Slider>
        </div>
      </Container>
    </FeaturesSliderWrapper>
  );
};

FeaturesSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeaturesSlider default style
FeaturesSlider.defaultProps = {
  button: {
    type: 'button',
    minWidth: '156px',
    maxWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    margin: '20px 0 0 20px' 
  },
  data: [
    {
      id: 1,
      heading: 'Dispatching',
      items: [
        {
          index: 0,
          slug: 'feature-dispaching-cloud-based-taxi-dispatching'
        },
        {
          index: 1,
          slug: 'feature-dispaching-location-assisted-automated-dispatch',
        },
        {
          index: 2,
          slug: 'feature-dispaching-universal-driver-app',
        },
        {
          index: 3,
          slug: 'feature-dispaching-passenger-app',
        },
      ]
    },
    {
      id: 2,
      heading: 'Communication',
      items: [
        {
          index: 4,
          slug: 'feature-dispaching-integrated-voip-phone-system',
        },
        {
          index: 5,
          slug: 'feature-dispaching-passenger-text-messaging',
        },
        {
          index: 6,
          slug: 'feature-dispaching-masked-passenger-calling',
        },
      ]
    },
    {
      id: 3,
      heading: 'Integrated Payments',
      items: [
        {
          index: 7,
          slug: 'feature-dispaching-payment-processing',
        },
        {
          index: 8,
          slug: 'feature-dispaching-driver-cashiering',
        },
        {
          index: 9,
          slug: 'feature-dispaching-sameday-payments',
        },
      ]
    },
    {
      id: 4,
      heading: 'Others',
      items: [
        {
          index: 10,
          slug: 'feature-dispaching-nemt',
        },
        {
          index: 11,
          slug: 'feature-dispaching-reservation-management',
        },
      ]
    },
  ]
};

export default FeaturesSlider;
