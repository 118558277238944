import React, { useState, useEffect, Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Sticky from 'react-stickynode';
import { appTheme } from 'common/theme/app';
import {
  GlobalStyle,
  AppWrapper,
} from 'containers/App/app.style';
import { ResetCSS } from 'common/assets/css/style';
import Navbar from 'containers/App/Navbar';
import BannerSection from 'containers/Landing/Banner';
import FeatureSlider from 'containers/App/FeatureSlider';
import LoginModal from 'containers/App/LoginModal';
import FeaturesSlider from 'containers/App/FeaturesSection';
import Footer from 'containers/App/Footer';
import { DrawerProvider } from 'common/contexts/DrawerContext';
import CircularProgressOverlay from 'common/mycomponents/CircularProgressOverlay';
import SEO from 'components/seo';
import useBreakpoint from 'hooks/useBreakpoint';
import '@redq/reuse-modal/es/index.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MainPage = () => {
  const [loginOpen, setLoginOpen] = useState(false);

  const openLoginModal = () => {
    setLoginOpen(true);
  }

  const { allWpPost } = useStaticQuery(
    graphql`
      query {
        allWpPost {
          nodes {
            id
            uri
            title
            date
            slug
            content
          }
        }
      }
    `
  );
  const breakpoint = useBreakpoint().breakpoint;
  const isDesktop = ['xl', 'xxl'].includes(breakpoint);
  return (
    <ThemeProvider theme={appTheme}>
      <Fragment>
        <SEO title="PerGo Rides - Free dispatch and payment for transportation companies" />
        <ResetCSS />
        <GlobalStyle />
        <CircularProgressOverlay>
          <AppWrapper>
            <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
              <DrawerProvider>
                <Navbar page="landing" theme="white" />
              </DrawerProvider>
            </Sticky>
            <BannerSection />
            <FeatureSlider />
            <FeaturesSlider posts={allWpPost.nodes} openLogin={openLoginModal} />
            <Footer />

            <LoginModal open={loginOpen} handleClose={() => setLoginOpen(false)} />
          </AppWrapper>
        </CircularProgressOverlay>
      </Fragment>
    </ThemeProvider>
  );
};

export default MainPage;