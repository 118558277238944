import styled from 'styled-components';

export const BannerSectionContainer = styled.div`
  padding: 210px 0 160px;
  background-image: url(${props => props.imgBack});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  transition: all 2s cubic-bezier(0.4, 0, 1, 1);

  @media only screen and (min-width: 1367px) {
    min-height: 100vh;
  }

  .banner--wrapper {
    margin: 0 auto;
    padding: 0 28px;
    position: relative;
    z-index: 1;

    @media (min-width: 768px) {
      max-width: 750px;
    }
    @media (min-width: 992px) {
      max-width: 970px;
    }
    @media (min-width: 1200px) {
      max-width: 1170px;
    }
  }

  .banner--content-container {
    max-width: 50%;

    .banner--sub-heading {
      font-size: 17px;
      line-height: 20px;
      font-weight: 700;
    }

    .banner--heading {
      font-size: 70px;
      font-weight: 700;
      margin: 0;
    }

    .banner--button-container {
      margin-top: 40px;

      button {
        margin-right: 20px;
        padding: 15px 30px;
        font-size: 16px;
        border-radius: 50px;
        line-height: 16px;

        &.banner--button-passenger {
          background: rgba(139,55,255,1);
        }
        
        &.banner--button-driver {
          background: white;
          border: 1px solid rgba(12,11,49,0.45);
          color: black
        }
      }
    }
  }

  @media (max-width: 992px) {
    .banner--content-container {
      max-width: 70%;

      .banner--sub-heading {
        font-size: 16px;
      }

      .banner--heading {
        font-size: 50px;
      }
    }
  }

  @media (max-width: 576px) {
    padding: 150px 0;
    background-position: center right;

    .banner--wrapper {
      padding: 0 20px;
    }

    .banner--content-container {
      max-width: 100%;
      text-align: center;
      color: white;
      text-align: center;

      .banner--sub-heading {
        font-size: 16px;
      }

      .banner--heading {
        font-size: 40px;
      }

      .banner--button-container {
        button {
          margin: 0 0 20px 0;
          width: 80%;
        }
      }
    }
  }
`;

export const BackgroundPreLoader = styled.div`
  background: url(${props => props.img}) no-repeat;
  width: 0;
  height: 0;
`;