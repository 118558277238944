import styled from 'styled-components';
import Mockup from 'common/assets/image/app/iphone-mockup.png';

const FeaturesSliderWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  background: rgb(246, 248, 250);
  padding: 65px 40px 65px;
  .features-slider--title {
    text-align: center;
    margin-bottom: 50px;
    font-size: 40px;
  }
  .container {
    display: flex;
    flex-direction: row;
    overflow: auto;
    .features-slider--section--list {
      position: relative;
      margin: 20px 40px 20px 0px;
      width: 263px;
      .features-slider--section--list-heading {
        text-align: right;
        font-weight: bold;
        line-height: 1.5;
        font-size: 24px;
        color: #009de6;
        margin: 60px 0 15px;
      }
      .features-slider--section--list-title {
        cursor: pointer;
        text-align: right;
        font-weight: bold;
        line-height: 1.5;
        font-size: 18px;
        margin-top: 10px;
        margin-bottom: 20px;
        &.active {
          color: #009de6;
        }
      }
      img {
        position: absolute;
        right: -55px;
        top: 6px;
        transition: top 0.25s cubic-bezier(0.55, 0.085, 0.68, 0.53) 0s;
        z-index: 100;
      }
      .features-slider--mobile-title-slider {
        display: none;
      }
    }
    .features-slider--section--slider {
      flex: 1;
      overflow: hidden;
      background: linear-gradient(90deg, rgba(246, 248, 250, 0), rgb(246, 248, 250) 100%) 1px 0% repeat scroll, none 0% repeat scroll rgb(255, 255, 255);
      .features-slider--desktop-slider {
        width: 100%;
        z-index: 2;
        .slick-prev {
          left: 25px;
          z-index: 1;
        }
        .slick-next {
          right: 25px;
        }
        .slick-dots {
          bottom: 20px;
        }
        .features-slider--hero-section {
          width: 100%;
          max-height: fit-content;
          display: flex !important;
          padding: 20px 50px 70px 20px;
          .features-slider--container {
            height: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            width: 100%;
            overflow: auto;
            .features-slider--entry-title {
              font-size: 20px;
              text-align: center;
            }
            .features-slider--entry-content {
              padding: 20px;
              h1 {
                a {
                  color: gray;
                }
              }
              .wp-block-image {
                img {
                  margin: auto;
                }
              }
              .wp-block-video {
                video {
                  width: 100%;
                }
              }
              ul {
                li::before {
                  content: "•";
                  margin: 0 10px;
                }
              }
              p {
                line-height: 1.5;
                margin-bottom: 40px;
              }
              ul {
                line-height: 1.5;
              }
            }
          }
        }
      }
    }
    @media (max-width: 1220px) {
      max-width: unset;
    }
    @media (max-width: 994px) {
      flex-direction: column;
      padding: 0;
      .features-slider--section--slider {
        background: unset;
        .features-slider--desktop-slider {
          width: calc(100vw - 80px);
          .features-slider--hero-section {
            padding-right: 20px;
            padding-left: 0;
            height: 680px;
          }
          .slick-list {
            padding: 0 20px;
          }
        }
      }
      .features-slider--section--list {
        .features-slider--section--list-heading {
          display: none;
        }
        .features-slider--section--list-title {
          display: none;
        }
        margin: 0;
        width: calc(100vw - 80px);
        > p, > img {
          display: none;
        }
        .features-slider--mobile-title-slider {
          display: block;
          margin: auto;
          text-align: center;
          .features-slider--mobile-slider-title {
            padding: 0 20px;
            cursor: pointer;
            font-weight: bold;
            &.active {
              color: #009de6;
            }
          }
        }
      }
    }
  }
`;
export default FeaturesSliderWrapper;
